import React from 'react';
import ManufacturersSelect from '../../project/ManufacturersSelect';
import ExportButton from '../../project/ExportButton';
import Select from '../../general/Select';
import {Trans, t} from '@lingui/macro';
import CustomersSelect from '../../project/CustomersSelect';
import CategoriesTreeSelect from '../../project/CategoriesTreeSelect';

/**
 * @fero
 */

class ExportData extends React.PureComponent {

	constructor(props) {
        super(props);
        this.state = {
        	manufacturerId: null,
			customerId: null,
			categoryId: null,
        	exportType: null,
        };
    }

	onManufacturerChange = (newValue) => {
		this.setState({manufacturerId: newValue});
	};

	onCustomerChange = (newValue) => {
		this.setState({customerId: newValue});
	};

	onExportTypeChange = (newValue) => {
		this.setState({exportType: newValue});
	};

	onCategoryChange = (newValue) => {
		this.setState({categoryId: newValue});
	};

    render() {
    	const {manufacturerId, customerId, categoryId, exportType} = this.state;
    	const exportTypes = [
			{value: "product/export", label: <Trans>Export produktov</Trans>},
			{value: "product/export_list_prices", label: <Trans>Export brutto cien</Trans>},
			{value: "product/export_special_prices", label: <Trans>Export špeciálnych cien</Trans>},
			{value: "product/export_alt_categories", label: <Trans>Export alternatívnych kategórií</Trans>},
			{value: "product/export_params", label: <Trans>Export parametrov produktov</Trans>},
			{value: "product/export_barcodes", label: <Trans>Export čiarových kódov produktov</Trans>},
			{value: "product/export_custom_codes", label: <Trans>Export zákazníckych čísel produktov</Trans>},
			{value: "product/export_stock_params", label: <Trans>Export nastavení skladu</Trans>},
			{value: "product/export_replacements", label: <Trans>Export náhrad produktov</Trans>},
			{value: "product/export_components", label: <Trans>Export komponentov produktov</Trans>},
			{value: "product/export_variants", label: <Trans>Export variantov produktov</Trans>},
			{value: "product/export_relatives", label: <Trans>Export súvisiacich produktov</Trans>},
			{value: "product/export_keys", label: <Trans>Export identifikátorov produktov</Trans>},
			{value: "category/export", label: <Trans>Export kategórií</Trans>},
			{value: "discount/export_groups", label: <Trans>Export rabatových skupín</Trans>},
			{value: "discount/export_rules", label: <Trans>Export rabatových pravidiel</Trans>},
			{value: "customer/export", label: <Trans>Export organizácií</Trans>},
			{value: "user/export", label: <Trans>Export kontaktov</Trans>},
		];

        return <div className="p-3">
        	<h3><Trans>Export do MS Excel</Trans></h3>
        	<div className="d-flex align-items-center">
        		<span className="px-2 font-weight-bold"><Trans>Zostava:</Trans></span>
        		<Select
        			className="export-type-select"
        			value={exportType != null ? exportType : undefined}
        			onChange={this.onExportTypeChange} 
        			options={exportTypes}
        			placeholder={<Trans>Vyberte ...</Trans>}
                />
                { exportType != null && exportType.includes('product') ? 
	        		<ManufacturersSelect
	        			className="export-manufacturer-select"
	        			value={manufacturerId}
	        			onChange={this.onManufacturerChange}
	        			allowClear={true}
						isMulti={true}
	        		/>
	        		: null
	        	}
				{ exportType != null && (exportType == 'product/export_special_prices' || exportType == 'discount/export_rules' || exportType == 'product/export_custom_codes') ? 
	        		<CustomersSelect
	        			className="export-customer-select"
	        			value={customerId}
	        			onChange={this.onCustomerChange}
	        			allowClear={true}
						isMulti={true}
	        		/>
	        		: null
	        	}
				{ exportType != null && (exportType == 'product/export' || exportType == 'product/export_alt_categories') ? 
	        		<CategoriesTreeSelect
	        			className="export-customer-select"
	        			value={categoryId}
	        			onChange={this.onCategoryChange}
	        			allowClear={true}
						isMulti={true}
	        		/>
	        		: null
	        	}
	        	<ExportButton
	        		what={exportType}
	        		title={<Trans>Stiahnuť</Trans>}
	        		customerId={customerId}
					manufacturerId={manufacturerId}
					categoryId={categoryId}
	        		disabled={(exportType == null)}
	        	/>
        	</div>
        </div>;
    }

}

export default ExportData;